@charset "UTF-8";
/*
	Template Name: BizCraft Responsive HTML5/CSS3 Template
	Author: themefisher
	Author URI: http://themefisher.com
	Description: BizCraft Responsive HTML5/CSS3 Template.
	Version: 1.0
*/
/* Table of Content
==================================================
1.	Global Styles
2. 	Typography
3. 	Common Styles
4.	Header area
5.	Slideshow
6.	Services and Features
7.	Image Block
6.	About (Landing and Featured Tab)
9.	Portfolio
10.	Counter
11.	Team
12.	Pricing
13.	Testimonial
14. Video block
15.	Recent Post
16. Clients
17. Twitter
18. Contact
19. Footer
20. Copyright
21. Sub pages
22. Blog
23. Landing page
24.	Style Swithcer (you can delete the css) */
@import url(http://fonts.googleapis.com/css?family=Lato:100,300,400,700,900);
body,
p {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 1.6em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

h1,
.h1 {
  font-size: 36px;
  line-height: 48px;
}

h2,
.h2 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
}

h3,
.h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
}

h4,
.h4 {
  font-size: 18px;
  line-height: 28px;
}

h5,
.h5 {
  font-size: 14px;
  line-height: 24px;
}

.icon-xl {
  font-size: 200px;
}
@media (max-width: 991px) {
  .icon-xl {
    font-size: 100px;
  }
}
@media (max-width: 767px) {
  .icon-xl {
    font-size: 60px;
  }
}

.btn {
  color: #323232;
  border-radius: 50px;
  padding: 10px 30px;
  background: none;
  font-size: 14px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-width: 2px;
}
.btn:focus {
  box-shadow: none;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none;
}

.btn-outline-white {
  border: 2px solid #fff;
  color: #fff;
}
.btn-outline-white:hover {
  color: #000;
  background-color: #fff;
}

.btn-outline-primary {
  border: 2px solid #007bff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
}

html {
  overflow-x: hidden !important;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 15px;
  color: #6A6A6A;
  background: #fff;
}

h1,
h2,
h3 {
  color: #323232;
}

ul {
  padding: 0;
}

.body-inner {
  position: relative;
  overflow: hidden;
}

a:link,
a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

a.read-more {
  color: #323232;
}

section {
  padding: 80px 0;
  position: relative;
}

.ts-padding {
  padding: 50px;
}

.p-0 {
  padding: 0;
}

.media > .float-left {
  margin-right: 20px;
}

.heading {
  padding-bottom: 50px;
  margin-top: 10px;
  transition: all 0.3s ease 0s;
}

.gap-60 {
  clear: both;
  height: 60px;
}

.gap-40 {
  clear: both;
  height: 40px;
}

.gap-30 {
  clear: both;
  height: 30px;
}

.gap-20 {
  clear: both;
  height: 20px;
}

a:focus {
  outline: 0;
}

ul.circle,
ul.check-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.circle li {
  position: relative;
  margin-left: 30px;
  padding-bottom: 20px;
}

ul.circle li:before {
  content: "";
  font-family: FontAwesome;
  font-size: 8px;
  line-height: 8px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  position: absolute;
  left: -33px;
  top: 5px;
}

ul.unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.check-list li {
  margin-bottom: 5px;
}

ul.check-list li i {
  margin-right: 10px;
  font-size: 14px !important;
}

ul.arrow li:before {
  content: "";
  font-family: FontAwesome;
  margin-right: 10px;
}

blockquote p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  font-style: italic;
  color: #959595;
}

blockquote {
  margin: 20px 0;
  padding: 10px 20px;
}

/* Common styles
================================================== */
.text--primary {
  color: #007bff;
}

@media (min-width: 768px) {
  .border-md-right {
    border-right: 1px solid #dee2e6;
  }
}

/* Button */
.button-group .btn {
  margin-bottom: 5px;
  margin-right: 10px;
}
/* Angle */
.angle:before {
  background: #fff;
  content: "";
  height: 75px;
  top: -37px;
  position: absolute;
  width: 100%;
  z-index: 2;
  transform: skew(0deg, 2deg);
}

.portfolio.angle:before {
  background: #f5f5f5;
}

.portfolio.angle {
  padding-bottom: 120px;
}

.clients.angle {
  padding: 120px 0;
}

/* Parallax */
.parallax {
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-attachment: fixed !important;
  padding: 110px 0;
  color: #fff;
  position: relative;
}

.parallax h2,
.parallax h3 {
  color: #fff;
  margin-top: 0;
}

.parallax-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../images/overaly.png);
}

.parallax h3 {
  margin-bottom: 30px;
}

.parallax1 {
  background: url(../images/parallax/parallax1.jpg) no-repeat;
}

.parallax2 {
  background: url(../images/parallax/parallax2.jpg) no-repeat;
}

.parallax3 {
  background: url(../images/parallax/parallax3.jpg) no-repeat;
}

.parallax4 {
  background: url(../images/parallax/parallax4.jpg) no-repeat;
}

.parallax .btn.solid {
  margin-left: 10px;
}

/* Deafult title */
.title-icon {
  font-size: 32px;
  margin-right: 15px;
  margin-top: 9px;
  color: #959595;
}

.title-icon.classic {
  margin-top: 0;
}

.title {
  position: relative;
  margin: 0;
  line-height: 32px;
  border-left: 2px solid #e7e7e7;
  display: inline-block;
  padding-left: 15px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.title.classic {
  line-height: 40px;
  height: 34px;
}

.title-desc {
  font-family: "Lato", serif;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  text-transform: capitalize;
  display: block;
  color: #6A6A6A;
}

/* Title style 2 */
.title2 {
  position: relative;
  margin-top: 30px;
}

.title2:after {
  content: "";
  width: 150px;
  left: 50%;
  margin-left: -75px;
  top: 50%;
  height: 3px;
  background: #dedede;
  position: absolute;
}

.title2 .title-desc {
  padding-top: 30px;
}

/* Title border */
.title-border {
  line-height: normal;
  margin-top: 0;
}

.widget-title {
  margin-bottom: 25px;
  font-size: 20px;
}

.post-title {
  font-size: 24px;
  line-height: normal;
}

.post-title a {
  color: #323232;
}

.long-desc {
  font-size: 16px;
}

/* Icon pentagon */
.icon-pentagon {
  display: inline-block;
  height: 24px;
  width: 40px;
  position: relative;
  color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.icon-pentagon:hover {
  color: rgba(0, 0, 0, 0.5);
}

.icon-pentagon:before {
  border-left: 20px solid rgba(0, 0, 0, 0);
  border-right: 20px solid rgba(0, 0, 0, 0);
  top: -10px;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  width: 0;
}

.icon-pentagon:after {
  border-left: 20px solid rgba(0, 0, 0, 0);
  border-right: 20px solid rgba(0, 0, 0, 0);
  bottom: -10px;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  width: 0;
}

.icon-pentagon i {
  font-size: 20px;
  line-height: 24px;
}

.service-icon {
  height: auto;
  width: auto;
  margin-bottom: 20px;
}

.service-icon:before {
  border-left: 45px solid rgba(0, 0, 0, 0);
  border-right: 45px solid rgba(0, 0, 0, 0);
  top: -30px;
}

.service-icon:after {
  border-left: 45px solid rgba(0, 0, 0, 0);
  border-right: 45px solid rgba(0, 0, 0, 0);
  bottom: -30px;
}

.service-icon i {
  font-size: 36px;
  line-height: 46px;
  color: #6A6A6A;
}

/* Carousel controller */
.carousel-control {
  width: auto;
}

.ts-carousel-controller {
  text-align: center;
  padding-top: 50px;
  z-index: 1;
  position: relative;
}

.ts-carousel-controller .left,
.ts-carousel-controller .right,
.owl-controls .owl-prev,
.owl-controls .owl-next {
  cursor: pointer;
  background: #f6f6f6;
  width: 38px;
  height: 38px;
  line-height: 38px;
  color: #989898;
  opacity: 1;
  top: auto;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
}

.owl-controls .owl-next {
  margin-left: 6px;
}

.ts-carousel-controller .left:hover,
.ts-carousel-controller .right:hover,
.owl-controls .owl-prev:hover,
.owl-controls .owl-next:hover {
  color: #fff;
}

.owl-controls .owl-page,
.owl-controls .owl-buttons div {
  display: inline-block;
}

/* Input form */
.form-control {
  box-shadow: none;
  border: 1px solid #dedede;
  padding: 6px 20px;
  height: 50px;
  background: none;
  color: #959595;
  font-size: 14px;
  border-radius: 0;
}

.form-control:focus {
  box-shadow: none;
}

/*-- Flex slider --*/
.portfolio-slider .flexslider,
.ts-flex-slider .flexslider {
  border: 0;
  box-shadow: none;
}

.portfolio-slider .flex-direction-nav a,
.ts-flex-slider .flex-direction-nav a {
  opacity: 0;
  background: #000;
  color: #fff !important;
  text-align: center;
  text-shadow: none;
  line-height: 40px;
}

.portfolio-slider .flex-direction-nav a:before {
  font-size: 32px;
}

.portfolio-slider .flex-direction-nav a:hover,
.ts-flex-slider .flex-direction-nav a:hover {
  color: #fff;
  opacity: 1;
}

.flexportfolio.flexslider .slides img {
  height: auto;
}

/*-- Pagination --*/
.pagination li a {
  border-radius: 0 !important;
  margin-right: 8px;
}

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > li > a:hover {
  color: #fff;
  border: 1px solid transparent;
}

.page-link:hover {
  background-color: #007bff;
}

/* Main navigation */
.navbar {
  padding: 0;
}
.navbar-toggler:focus {
  outline: 0;
}
@media (max-width: 991px) {
  .navbar-collapse {
    background-color: black;
    margin-top: 20px;
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-light .navbar-nav .nav-link {
  color: #333;
}

.navbar-nav .nav-link {
  text-transform: uppercase;
  font-weight: bold;
  padding: 20px 15px 18px !important;
  transition: 0.2s ease;
  font-size: 14px;
}
@media (max-width: 991px) {
  .navbar-nav .nav-link {
    padding: 10px !important;
  }
}

/* Dropdown */
.dropdown:hover .dropdown-menu {
  visibility: visible;
  opacity: 1;
  transform: scaleX(1);
}
.dropdown-item {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  padding: 15px;
  color: #333;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  transition: 0.2s ease;
}
.dropdown-item:hover {
  background: #fff;
}
.dropdown-menu {
  padding: 0 15px;
  border: 0;
  border-radius: 0px;
  width: 100%;
  box-shadow: none;
  background: none;
  min-width: 180px;
  top: 100%;
  left: -10px;
  border-radius: 0;
  display: block;
  visibility: hidden;
  transition: 0.3s ease;
  opacity: 0;
  transform: scale(0.8);
  background: #fff;
}
@media (max-width: 991px) {
  .dropdown-menu {
    display: none;
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    transform-origin: unset;
    text-align: center;
  }
}
.dropdown-menu.show {
  visibility: hidden;
}
@media (max-width: 991px) {
  .dropdown-menu.show {
    visibility: visible;
    display: block;
  }
}

/*-- Header style 1 --*/
@media (max-width: 991px) {
  .header .navbar {
    padding: 16.5px 0;
  }
}

.header-solid {
  background: #191919;
  box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.2);
}

/*-- Logo --*/
/* Header Style - 2
================================================== */
.header2 {
  padding: 8px 0;
}
@media (max-width: 991px) {
  .header2 {
    background: #191919;
    box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.2);
  }
}
.header2 .navbar {
  padding: 0;
}

.header2 .navbar-brand {
  background: none;
  padding-top: 3px;
}

/* Header Style - 3
================================================== */
.header3 .navbar {
  background: #fff;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.2);
}
.header3 .navbar-brand {
  background: transparent;
}
.header3 .navbar-toggler {
  background: transparent;
}

/* Header Style - 4
================================================== */
.header4 {
  background: #fff;
  box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.2);
}
.header4 .navbar-brand {
  background: transparent;
}
.header4 .navbar-toggler {
  background: transparent;
}

/* Slideshow
================================================== */
/*-- Main slide --*/
.overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(../images/overaly.png);
}

#main-slide .carousel-item img {
  width: 100%;
  -webkit-backface-visibility: hidden;
}

#main-slide .carousel-item {
  -webkit-backface-visibility: hidden;
}

#main-slide .carousel-item .slider-content {
  z-index: 1;
  opacity: 0;
  transition: opacity 500ms;
}

#main-slide .carousel-item.active .slider-content {
  opacity: 1;
  transition: opacity 100ms;
}

#main-slide .slider-content {
  top: 50%;
  margin-top: -70px;
  left: 0;
  padding: 0;
  text-align: center;
  position: absolute;
  width: 100%;
  color: #fff;
}
@media (max-width: 767px) {
  #main-slide .slider-content {
    margin-top: 0;
  }
}

#main-slide .carousel-indicators {
  bottom: 70px;
}

.carousel-indicators li {
  width: 30px !important;
  height: 10px !important;
  margin: 1px !important;
  border-radius: 0 !important;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid #fff;
}

#main-slide .carousel-indicators li.active,
#main-slide .carousel-indicators li:hover {
  transition: all 300ms ease;
}

#main-slide .carousel-control.left,
#main-slide .carousel-control.right {
  opacity: 0;
  filter: alpha(opacity=0);
  background-image: none;
  background-repeat: no-repeat;
  text-shadow: none;
  transition: all 0.25s ease;
}

#main-slide:hover .carousel-control.left,
#main-slide:hover .carousel-control.right {
  opacity: 1;
  filter: alpha(opacity=100);
}

#main-slide:hover .carousel-control.left {
  left: 20px;
}

#main-slide:hover .carousel-control.right {
  right: 20px;
}

#main-slide .carousel-control.left span {
  padding: 15px;
}

#main-slide .carousel-control.right span {
  padding: 15px;
}

#main-slide .carousel-control .fa-angle-left,
#main-slide .carousel-control .fa-angle-right {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
}

#main-slide .carousel-control .fa-angle-left {
  left: 0;
}

#main-slide .carousel-control .fa-angle-right {
  right: 0;
}

#main-slide .carousel-control i {
  background: none;
  color: #fff;
  line-height: 56px;
  width: 56px;
  height: 56px;
  border: 1px solid #fff;
  border-radius: 100%;
  font-size: 36px;
  transition: all 500ms ease;
}

#main-slide .carousel-control i:hover {
  border: 1px solid rgba(0, 0, 0, 0.3);
}

#main-slide .slider-content h2 {
  font-size: 52px;
  text-transform: uppercase;
  color: #fff;
}

#main-slide .slider-content h3 {
  font-size: 28px;
  margin-top: 40px;
  color: #fff;
}

.slider.btn {
  padding: 10px 35px;
  margin-top: 40px;
  font-size: 16px;
  text-transform: uppercase;
}

.slider.btn.solid {
  margin-left: 15px;
}

.slider-content-left {
  position: relative;
  margin: 0 0 0 40px;
}

.slider-content-right {
  position: relative;
}

/*-- Animation */
.carousel .carousel-item.active .animated2,
.flex-active-slide .animated2 {
  -webkit-animation: lightSpeedIn 1s ease-in 200ms both;
  animation: lightSpeedIn 1s ease-in 200ms both;
}

.carousel .carousel-item.active .animated3,
.flex-active-slide .animated3 {
  -webkit-animation: bounceInLeft 2s ease-in-out 800ms both;
  animation: bounceInLeft 1.5s ease-in-out 800ms both;
}

.carousel .carousel-item.active .animated4,
.flex-active-slide .animated4 {
  -webkit-animation: flipInX 2s ease-in 800ms both;
  animation: flipInX 2s ease-in 800ms both;
}

.carousel .carousel-item.active .animated5,
.flex-active-slide .animated5 {
  -webkit-animation: bounceInLeft 1s ease-in-out 100ms both;
  animation: bounceInLeft 1s ease-in-out 100ms both;
}

.carousel .carousel-item.active .animated6,
.flex-active-slide .animated6 {
  -webkit-animation: bounceIn 1s ease-in 500ms both;
  animation: bounceIn 1s ease-in 500ms both;
}

.carousel .carousel-item.active .animated7,
.flex-active-slide .animated7 {
  -webkit-animation: bounceIn 1s ease-in 500ms both;
  animation: bounceIn 1s ease-in 500ms both;
}

.carousel .carousel-item.active .animated8,
.flex-active-slide .animated8 {
  -webkit-animation: bounceInDown 1s ease-in 800ms both;
  animation: bounceInDown 1s ease-in 800ms both;
}

/* Flex slider */
.ts-flex-slider {
  position: relative;
}

.ts-flex-slider .flexSlideshow {
  width: 100%;
  overflow: hidden;
}

.overlay2 {
  position: relative;
}

.overlay2 img {
  width: 100%;
}

.overlay2::before {
  position: absolute;
  content: "";
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(../images/overaly.png);
}

.ts-flex-slider .flexslider .slides {
  z-index: 0;
}

.ts-flex-slider .slider-content {
  z-index: 3;
}

.ts-flex-slider .flex-direction-nav a {
  width: 55px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 32px;
}

.ts-flex-slider .flex-direction-nav a.flex-next {
  border-radius: 3px;
}

.ts-flex-slider .flex-direction-nav a.flex-prev {
  border-radius: 3px;
}

/* Services
================================================== */
.service {
  padding-top: 40px;
}

.service-heading {
  padding-bottom: 30px;
  text-align: center;
}

.service-content {
  margin-top: 30px;
}

.service-content h3 {
  font-size: 16px;
}

/* Feature box
================================================== */
.feature-box {
  margin-bottom: 40px;
}
@media (min-width: 992px) {
  .feature-box:nth-last-child(-n+3) {
    margin-bottom: 0;
  }
}
@media (max-width: 991px) {
  .feature-box:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}
@media (max-width: 767px) {
  .feature-box:last-child {
    margin-bottom: 0;
  }
}
.feature-icon {
  font-size: 28px;
  margin-top: 5px;
}
.feature-content {
  padding-right: 20px;
  padding-left: 55px;
}
.feature-content h3 {
  margin-top: 0;
  font-size: 18px;
}

/* Feature center */
.feature-center-content .feature-center-icon {
  font-size: 72px;
  margin-bottom: 5px;
  display: inline-block;
}

.feature-center-content h3 {
  font-size: 18px;
}

/* Image Block
================================================== */
.image-block {
  background: #f4f4f4;
}

.img-block-right {
  padding: 50px 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1200px) {
  .img-block-right {
    padding: 40px;
  }
}
.img-block-right .img-block-head {
  padding: 0 120px;
}
@media (max-width: 1500px) {
  .img-block-right .img-block-head {
    padding: 0;
  }
}
.img-block-right .image-block-content {
  margin-bottom: 30px;
}
.img-block-right .image-block-content:last-child {
  margin-bottom: 0;
}
.img-block-right .image-block-content:last-child p {
  margin-bottom: 0;
}

.img-block-head h2 {
  font-size: 18px;
  line-height: normal;
  margin: 5px 0 0 0;
}

.img-block-head h3 {
  font-size: 40px;
  line-height: 100%;
}

.image-block-content .feature-icon {
  width: 60px;
  height: 60px;
  line-height: 60px;
  color: #6A6A6A;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
}

.image-block-content .feature-content {
  padding-left: 85px;
}

.image-block-content .feature-content h3 {
  margin-bottom: 0;
}

/* About us
================================================== */
/* Landing Tab */
.landing-tab .nav-pills .nav-link {
  position: relative;
  color: #6A6A6A;
  transition: 0.2s ease;
  font-weight: bold;
}
@media (max-width: 991px) {
  .landing-tab .nav-pills .nav-link .h4 {
    font-size: 16px;
  }
}
.landing-tab .nav-pills .nav-link i {
  font-size: 30px;
}
.landing-tab .nav-pills .nav-link::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 5px;
  right: -3px;
  top: 0;
  opacity: 0;
}
.landing-tab .nav-pills .nav-link.active, .landing-tab .nav-pills .nav-link:hover {
  background: transparent;
  color: #007bff;
}
.landing-tab .nav-pills .nav-link.active::before, .landing-tab .nav-pills .nav-link:hover::before {
  opacity: 1;
}

/* Featured Tab */
.featured-tab .nav-link {
  position: relative;
  background: #f2f2f2;
  border-radius: 0;
  padding: 10px;
  transition: all 300ms ease;
}
.featured-tab .nav-link.active:hover {
  color: #fff !important;
}
.featured-tab .nav-link::before {
  left: 100%;
  border: transparent solid;
  content: "";
  position: absolute;
  border-width: 25px;
  top: 50%;
  transform: translateY(-50%);
  border-left-color: #f2f2f2;
  transition: inherit;
}
@media (max-width: 991px) {
  .featured-tab .tab-pane h3 {
    font-size: 20px;
  }
}
.featured-tab .tab-content > .active {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.featured-tab .nav.nav-pills {
  height: 100%;
  display: flex;
  justify-content: center;
}

/* Portfolio
================================================== */
.portfolio {
  background: #f5f5f5;
  padding-bottom: 0;
}
#main-container.portfolio {
  background: none;
}

/*-- Isotope Portfolio start --*/
#isotope .isotope-item {
  padding: 0;
}
@media (max-width: 767px) {
  #isotope .isotope-item {
    padding: 10px;
  }
}

.isotope-nav {
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 991px) {
  .isotope-nav {
    justify-content: center;
  }
}

.isotope-nav ul {
  list-style: none;
  margin: 0;
}

.isotope-nav ul li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 20px;
}

.isotope-nav ul li a {
  color: #323232;
  padding: 5px 15px 8px;
  border: 1px solid #ddd;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.isotope-nav ul a.active,
.isotope-nav ul a:hover {
  background-color: #323232;
  color: #fff;
}

#isotope .isotope-item .grid {
  position: relative;
  margin-bottom: -8px;
}

.grid figure {
  position: relative;
  z-index: 1;
  display: inline-block;
  overflow: hidden;
  background: #3085a3;
  text-align: center;
  width: 100%;
  height: 100%;
}

.grid figure figcaption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.grid figure figcaption {
  padding: 2em;
  color: #fff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-oscar {
  background: #000;
  background: rgba(0, 0, 0, 0.8);
}

figure.effect-oscar img {
  display: block;
  width: 100%;
  opacity: 1;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure.effect-oscar figcaption {
  padding: 2.5em;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transition: background-color 0.35s;
  transition: background-color 0.35s;
}

figure.effect-oscar figcaption::before {
  position: absolute;
  top: 30px;
  right: 30px;
  bottom: 30px;
  left: 30px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  content: "";
}

figure.effect-oscar h3 {
  font-size: 18px;
  color: #fff;
  opacity: 0;
  margin: 25% 0 10px 0;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

figure.effect-oscar a {
  color: #fff;
  font-size: 18px;
  margin: 20px 5px 0;
  display: inline-block;
  z-index: 1;
  position: relative;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}

figure.effect-oscar a.link i {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

figure.effect-oscar figcaption::before,
figure.effect-oscar a {
  opacity: 0;
  -webkit-transition: all 0.35s, -webkit-transform 0.35s;
  transition: all 0.35s, transform 0.35s;
  -webkit-transform: scale(0);
  transform: scale(0);
}

figure.effect-oscar:hover h3 {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

figure.effect-oscar:hover figcaption::before,
figure.effect-oscar:hover h3,
figure.effect-oscar:hover a {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

figure.effect-oscar:hover figcaption {
  background-color: rgba(0, 0, 0, 0.5);
}

figure.effect-oscar:hover img {
  opacity: 0.4;
}

/* Portfolio box */
.portfolio-box {
  padding-bottom: 80px;
}
.portfolio-box .icon-pentagon {
  color: #959595;
}

.portfolio-box .isotope-nav {
  margin: 0 auto 60px;
  float: none;
}
@media (max-width: 575px) {
  .portfolio-box .isotope-nav {
    margin-bottom: 40px;
  }
}

/* Portfolio details */
.portfolio-static-item {
  padding-bottom: 20px;
}

.portfolio-static-item figure.effect-oscar a {
  margin-top: 70px;
}

.portfolio-static-desc h3 {
  font-size: 16px;
  margin: 5px 0 -5px 0;
  line-height: normal;
}

.portfolio-static-desc a {
  color: #6A6A6A;
}

/* Portfolio details */
.portfolio-desc h3 {
  margin-top: 0;
}

.portfolio-desc .project-btn {
  margin-top: 20px;
  padding: 8px 25px;
}

/* Counter
================================================== */
.facts-wrapper {
  background: #2a2d32;
  color: #fff;
}

.facts {
  position: relative;
  padding: 46px 40px 30px;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.facts:hover {
  background: #000 !important;
}

.facts.one {
  background: rgba(0, 0, 0, 0.3);
}

.facts.two {
  background: rgba(0, 0, 0, 0.2);
}

.facts.three {
  background: rgba(0, 0, 0, 0.15);
}

.facts.four {
  background: rgba(0, 0, 0, 0.09);
}

.facts i {
  font-size: 36px;
  text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.3);
}

.facts-num span {
  font-size: 42px;
  font-weight: 700;
  margin-top: 20px;
  display: inline-block;
}

.facts h3 {
  font-size: 18px;
  margin-top: 8px;
  color: #fff;
}

/* Counter parallax bg */
.ts_counter_bg .facts {
  padding: 0 15px;
}

.ts_counter_bg .facts:hover {
  background: none !important;
}

.ts_counter_bg .facts-num span {
  margin: 30px 0 20px;
}

.ts_counter.elements .facts {
  padding: 15px;
}

/* Team
================================================== */
.img-top {
  top: 0;
  border-top: 0px solid transparent;
  border-bottom: 75px solid transparent;
}

.img-bottom {
  bottom: 0px;
  border-bottom: 0px solid transparent;
  border-top: 75px solid transparent;
}

.img-hexagon {
  width: 240px;
  height: 250px;
  position: relative;
  display: inline-block;
}
@media (max-width: 1200px) {
  .img-hexagon {
    width: 220px;
    height: 240px;
  }
}
.img-hexagon span {
  position: absolute;
  display: block;
  float: left;
  border-left: 120px solid #fff;
  border-right: 120px solid #fff;
}
@media (max-width: 1200px) {
  .img-hexagon span {
    border-right: 110px solid #fff;
    border-left: 110px solid #fff;
  }
}

.img-hexagon img {
  width: 100%;
  height: 100%;
}

.team-content h3 {
  margin-bottom: 0;
  font-size: 20px;
}

.team-content p {
  color: #959595;
}

.team-social a {
  background: #d5d5d5;
  color: #fff;
  width: 28px;
  height: 28px;
  line-height: 28px;
  border-radius: 100%;
  display: inline-block;
  text-align: center;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.team-social a.fb:hover {
  background: #325c94;
}

.team-social a.twt:hover {
  background: #00abdc;
}

.team-social a.gplus:hover {
  background: #dd4b39;
}

.team-social a.linkdin:hover {
  background: #007bb6;
}

.team-social a.dribble:hover {
  background: #ea4c89;
}

/* Team list */
.team-list .img-hexagon {
  float: left;
  margin-right: 30px;
}

@media (max-width: 991px) {
  .team-list,
.team-list-square {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
}
.team-list .team-content,
.team-list-square .team-content {
  display: flex;
  flex-direction: column;
}

/* Team list square */
.team-list-square .img-square {
  float: left;
  margin-right: 30px;
}

/* Pricing table
================================================== */
.pricing {
  padding-bottom: 80px;
}

.plan {
  border: 1px solid #dedede;
}
.plan:hover a.btn {
  color: #fff;
  background-color: #007bff;
}
.plan:hover .plan-price {
  color: #007bff;
}

.plan-name {
  display: block;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  padding: 20px 0;
  color: #959595;
}

.plan-price {
  font-size: 48px;
  padding: 30px 0;
  margin-bottom: 30px;
  position: relative;
  background: #f2f2f2;
  transition: 0.3s;
}
.plan-price:after {
  bottom: -30px;
  border: solid transparent;
  content: " ";
  position: absolute;
  border-width: 15px;
  border-top-color: #f2f2f2;
  left: 50%;
  margin-left: -16px;
}

.plan.featured .plan-name {
  color: #323232;
}

.plan.featured .plan-price {
  color: #007bff;
}

.featured .plan a.btn {
  background-color: #007bff;
  color: #fff;
}

.plan-price .currency {
  top: -0.9em;
  font-size: 50%;
  left: -0.01em;
  font-weight: 700;
}

.plan-name small {
  display: block;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
}

.plan-price sub {
  font-size: 18px;
  font-weight: 700;
}

.plan ul {
  background: #fff;
  padding: 0;
  margin-bottom: 0;
}

.plan ul li {
  border-top: 1px dashed #dedede;
  padding: 12px 29px;
  font-weight: 500;
}

.plan ul li:first-child {
  border-top: 0 !important;
}

.plan a.btn {
  background: #fff;
  margin-top: 25px;
  margin-bottom: -20px;
  padding: 8px 30px;
}

.plan a.btn:hover {
  background-color: #007bff;
}

/* Testimonial
================================================== */
.testimonial-thumb img {
  border: 1px solid #8E8E8E;
  padding: 3px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
}

.testimonial-slide .testimonial-content h3.name {
  font-size: 18px;
  color: #fff;
  margin: 10px 0;
}

.testimonial-slide .testimonial-content h3 span {
  display: block;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  margin-top: -10px;
}

.testimonial-slide .owl-buttons {
  display: none;
}

.testimonial-slide .testimonial-text {
  font-size: 16px;
  line-height: 24px;
  max-width: 800px;
  padding-top: 30px;
  display: inline-block;
  position: relative;
  color: #fff;
}

.testimonial-slide .testimonial-text:before {
  content: "";
  font-family: FontAwesome;
  padding-right: 10px;
}

.testimonial-slide .testimonial-text:after {
  content: "";
  font-family: FontAwesome;
  padding-left: 10px;
}

.testimonial-slide.owl-theme .owl-controls .owl-page span {
  background: none;
  border: 1px solid #fff;
  opacity: 0.7;
}

.testimonial-slide.owl-theme .owl-controls .owl-page.active span {
  background-color: #fff;
}

/* Video block
================================================== */
.featured-video {
  background: #fff;
}

.video-block-head h2 {
  font-size: 16px;
  margin: 0;
  line-height: normal;
}

.video-block-head h3 {
  font-size: 24px;
  line-height: normal;
  margin: 0;
}

.video-block-content {
  padding: 10px 0;
}

.video-block-content h3 {
  margin: 0;
  line-height: normal;
}

.video-block-content .btn.btn-primary {
  color: #fff;
  margin: 5px 0 0 40px;
  padding: 8px 25px;
}

.video-block-content .btn.btn-primary:hover {
  color: #333;
}

/* Recent Post
================================================== */
.post-img-wrapper {
  position: relative;
}

.post-img-overlay {
  position: relative;
  display: inline-block;
}

.post-img-overlay::before {
  position: absolute;
  border-radius: 1px;
  background-color: rgba(0, 0, 0, 0);
  content: "";
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}

.post-img-overlay:hover::before {
  background-color: rgba(0, 0, 0, 0.5);
}

.post-img-overlay a {
  position: absolute;
  right: 0;
  color: #fff;
  background: #666666;
  padding: 15px 20px;
  opacity: 0;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}

.post-img-overlay a.link {
  right: 0;
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
}

.post-img-overlay:hover a.link {
  left: 0;
  right: auto;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.post-img-overlay a.view {
  -webkit-transition: -webkit-transform 0.35s;
  transition: transform 0.35s;
  -webkit-transform: translate3d(0, -100%, 0);
  transform: translate3d(0, -100%, 0);
}

.post-img-overlay:hover a.view {
  bottom: 0;
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.recent-post {
  padding: 0 15px;
}

.recent-post .post-body h3 {
  font-size: 18px;
  margin-bottom: 3px;
  font-weight: 700;
}

.recent-post .post-body h3 a {
  color: #323232;
}

.recent-post .post-body .post-meta a {
  font-weight: 400;
  font-size: 13px;
}

.recent-post .post-body .post-meta span,
.recent-post .post-body .post-meta span i {
  padding-right: 5px;
}

.recent-post .post-excerpt {
  padding-top: 10px;
}

.recent-post .post-excerpt a.read-more {
  padding: 6px 18px;
  border-width: 1px;
  font-size: 12px;
  margin-top: 10px;
}

/* Clients
================================================== */
.clients {
  color: #fff;
  padding-bottom: 80px;
}

.clients-content {
  padding: 40px 0;
}

.clients h2.title {
  border-left: 0;
  color: #fff;
}

.client-carousel .item a {
  margin-right: 20px;
  border: 1px solid #e7e7e7;
  border-radius: 3px;
  display: inline-block;
  padding: 10px;
  -webkit-transition: all 350ms;
  -ms-transition: all 350ms;
  transition: all 350ms;
}

/* Twitter
================================================== */
.ts_twitter {
  padding: 100px 0;
}

.twitter-feed .twitter-icon i {
  font-size: 78px;
  color: #1dcaff;
}

.twitter-feed .tweet a {
  color: #1dcaff;
}

.twitter-feed .tweet a:hover {
  color: #000;
}

.twitter-feed .tweet p {
  font-size: 18px;
  font-style: italic;
  padding-top: 20px;
}

/* Contact
================================================== */
#contact-form {
  margin-top: 25px;
}

#contact-form label {
  font-weight: normal;
}

.map-wrapper {
  position: relative;
}

.map {
  height: 400px;
  z-index: 1;
}

.contact-info-inner h3 {
  margin-bottom: 25px;
  font-size: 24px;
  color: #fff;
}

.contact-info h3 {
  margin-top: 42px;
  font-size: 24px;
}

.contact-info p {
  font-size: 18px;
}

.contact-info i {
  font-size: 18px;
  margin-right: 10px;
}

/* Contact inside Map */
.contact-info-inner {
  position: absolute;
  color: #fff;
  top: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.7);
  padding: 25px 30px;
  max-width: 400px;
  max-height: 400px;
  text-align: left;
}

.contact-info-inner p {
  overflow: hidden;
  margin-bottom: 25px;
}

.contact-info-inner p strong {
  display: block;
}

.contact-info-inner i {
  margin-right: 20px !important;
  font-size: 24px;
  margin-top: 5px;
}

/* Footer
================================================== */
.footer {
  padding: 60px 0 100px;
  background: #2e2e2e url(../images/footer-bg.jpg) no-repeat center 0;
  background-size: cover;
  color: #fff;
  font-size: 14px;
}
.footer-logo {
  margin-bottom: 20px;
}
.footer-map {
  padding-bottom: 60px;
}
.footer .subscribe {
  position: relative;
}
.footer .subscribe .input-group-addon {
  position: absolute;
  right: 0;
}
.footer .form-control:focus {
  background: none;
}
.footer .subscribe button:hover {
  background: none;
}

.footer2 {
  background: #2e2e2e;
}

.footer .widget-title {
  color: #fff;
}

.footer .social-icon {
  padding: 10px 0 20px;
}

/* Latest Post */
.footer-widget .latest-post-image img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 4px solid rgba(255, 255, 255, 0.5);
}

.footer-widget .latest-post-content h4 a {
  color: #fff;
}

.latest-post-items {
  margin-bottom: 10px;
}

.latest-post-image img {
  width: 70px;
  height: 60px;
}

.footer-widget .post-meta,
.footer-widget .post-meta a {
  color: #fff;
}

.latest-post-content h4 {
  font-size: 14px;
  line-height: normal;
}

/* Image Gallery */
.footer-widget .img-gallery {
  margin-top: 5px;
  display: inline-block;
}

.img-gallery a.thumb-holder {
  overflow: hidden;
  position: relative;
}

.img-gallery a.thumb-holder img {
  margin: 0 15px 15px 0;
  display: inline-block;
  width: 95px;
  height: 85px;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.img-gallery a.thumb-holder:hover img {
  opacity: 0.7;
}

.footer2 .img-gallery a.thumb-holder img {
  width: 90px;
  height: 75px;
}

/* Footer about us */
.footer-about-us h4 {
  font-size: 16px;
  margin-bottom: 0;
  line-height: normal;
}

.subscribe {
  margin-top: 20px;
}

.subscribe input.form-control {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.subscribe .input-group-addon {
  background: none;
  border-radius: 0;
  border: 0;
  padding: 0;
}

.subscribe button {
  padding: 13px 20px;
  color: #fff;
}

.subscribe button:hover {
  background: #222;
  color: #fff;
}

.subscribe button i {
  font-size: 20px;
}

/* Footer links */
.footer ul li {
  color: #fff;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.footer ul.arrow li {
  width: 50%;
  float: left;
}

ul.arrow li:before {
  content: "";
  font-family: FontAwesome;
  margin-right: 10px;
}

.footer ul li a {
  color: #fff;
}

/* Copyright
================================================== */
#copyright {
  padding: 30px 0;
  background: #191919;
  color: #fff;
}

.copyright-info {
  font-size: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  max-width: 850px;
  margin: 30px auto 0;
  padding-top: 15px;
}

.copyright.angle:before {
  background: #191919;
  height: 50px;
  top: -25px;
  -webkit-transform: skew(0deg, -2deg);
  -moz-transform: skew(0deg, -2deg);
  -ms-transform: skewX(0deg) skewY(-2deg);
  -o-transform: skew(0deg, -2deg);
  transform: skew(0deg, -2deg);
}

.footer-social {
  position: relative;
  z-index: 10;
}

.footer-social .icon-pentagon i {
  font-size: 16px;
}

/* Footer sytle 2 */
.footer2 .social-icon {
  padding-bottom: 15px;
}

.footer2 .copyright-info {
  color: #323232;
  border-top: 1px solid #e7e7e7;
}

#back-to-top {
  right: 40px;
  top: auto;
  z-index: 10;
  -webkit-backface-visibility: hidden;
}

#back-to-top.affix {
  bottom: 60px;
}

#back-to-top .btn.btn-primary {
  border-radius: 100%;
  padding: 0;
  font-size: 18px;
  width: 36px;
  height: 36px;
  background: rgba(0, 0, 0, 0.3);
  border: 2px solid transparent;
  color: #fff;
  opacity: 0.5;
}

#back-to-top .btn.btn-primary:hover {
  color: #fff;
  opacity: 1;
}

/* Sub pages
================================================== */
/* Banner area */
#banner-area {
  position: relative;
}

.banner-title-content {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 100%;
}

.banner-title-content h2 {
  color: #fff;
  font-size: 36px;
}

.banner-title-content .breadcrumb {
  background: none;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}

.banner-title-content .breadcrumb a:hover {
  color: #ccc;
}

/* About Us */
.about-message {
  background: #f4f4f4;
  min-height: 374px;
}

.about-message ul li a {
  color: #6A6A6A;
}

/* Services */
/* Call to Action */
.call-to-action {
  color: #fff;
  padding: 40px 0;
}

.call-to-action.dark {
  background: #2a2d32;
}

.call-to-action h3 {
  color: #fff;
  float: left;
  margin: 10px 0 0;
  font-size: 20px;
}

.call-to-action .btn.btn-primary.white:hover {
  background: #000;
  color: #fff;
}

.call-to-action .btn.btn-primary.solid {
  margin-left: 15px;
}

/* Career */
.accordion .card-header a {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}
.accordion .card-header a::before, .accordion .card-header a.collapsed::before {
  font-family: FontAwesome;
  position: absolute;
  z-index: 0;
  font-size: 16px;
  left: 10px;
  padding: 0 5px;
  text-align: center;
}
.accordion .card-header a::before {
  content: "";
}
.accordion .card-header a.collapsed::before {
  content: "";
}

/* Testimonial */
.ts-testimonial-content {
  position: relative;
  padding: 30px 30px 15px 30px;
  border-width: 1px;
  border-style: solid;
  border-color: #e7e7e7;
  margin-bottom: 40px;
}

.ts-testimonial-content:after {
  content: "";
  position: absolute;
  bottom: -40px;
  left: 45px;
  border-style: solid;
  border-width: 40px 0px 0 30px;
  border-color: #e7e7e7 transparent;
  display: block;
  width: 0;
  z-index: 1;
}

.ts-testimonial-content img {
  float: left;
  width: 80px;
  height: 80px;
  border-radius: 100%;
}

.ts-testimonial-text {
  padding-left: 110px;
}

.ts-testimonial-author {
  position: absolute;
  bottom: -60px;
  left: 90px;
}

.ts-testimonial-author h3.name {
  font-size: 14px;
  margin: 10px 0 0;
}

.ts-testimonial-author h3 span {
  display: block;
  font-size: 13px;
  margin-top: -10px;
  text-transform: capitalize;
  color: #555;
}

/* Faq Page */
.faq-box {
  margin-bottom: 45px;
}

.faq-box h4 {
  margin-bottom: 25px;
}

/* 404 page */
.error-page .error-code {
  display: block;
  font-size: 150px;
  line-height: 150px;
  color: #333;
  margin-bottom: 20px;
  text-shadow: 5px 5px 1px rgba(0, 0, 0, 0.1);
}

.error-page .error-body .btn {
  margin-top: 30px;
  font-weight: 700;
}

/* Blog
================================================== */
/* Blog Listing */
.post {
  margin: 0 0 40px;
  padding: 0 0 40px;
  border-bottom: 1px solid #F5F5F5;
}

.post.post-last {
  border-bottom: 0;
}

.post-image-wrapper {
  margin-bottom: 20px;
  position: relative;
}

.blog-date {
  position: absolute;
  top: 50px;
  left: 0;
  color: #fff;
  border-radius: 0 25px 25px 0;
  padding: 5px 15px;
  font-weight: 700;
}

.blog-date a {
  color: #fff;
}

.post-footer .btn.btn-primary {
  font-size: 12px;
  padding: 8px 20px;
  margin-top: 15px;
}

/* Blog Single */
.post-meta {
  color: #959595;
  font-weight: 400;
  font-size: 13px;
}

.post-meta span {
  padding-right: 10px;
}

.post-meta a {
  color: #959595;
}

.post-meta-author a,
.post-meta-cats a {
  color: #323232;
}

.entry-content {
  padding: 20px 0 10px;
}

/* Blog author */
.about-author {
  background: #f2f2f2;
  padding: 15px 20px;
  margin: 20px 0;
}

.author-img img {
  width: 120px;
  height: 120px;
  margin-right: 30px;
  border-radius: 100%;
  margin-top: 20px;
}

.author-url span a {
  color: #959595;
}

.author-info h3 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: normal;
}

.author-info h3 span {
  font-size: 14px;
  color: #959595;
  border-left: 1px solid #AFAFAF;
  padding-left: 8px;
  margin-left: 5px;
}

/* Blog post comments */
.comments-counter {
  font-size: 18px;
}

.comments-counter a {
  color: #323232;
}

.comments-list {
  list-style: none;
  margin: 0;
  padding: 20px 0;
}

.comments-list .comment {
  border-bottom: 1px solid #cecece;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.comments-list .comment.last {
  border-bottom: 0;
}

.comments-list img.comment-avatar {
  width: 84px;
  height: 84px;
  margin-right: 30px;
  border-radius: 100%;
}

.comments-list .comment-body {
  margin-left: 120px;
}

.comments-list .comment-author {
  margin-bottom: 0;
}

.comments-list .comment-date {
  font-style: italic;
  color: #959595;
  margin-bottom: 20px;
}

.comments-list .comment-reply {
  text-transform: uppercase;
  color: #323232;
}

.comments-reply {
  list-style: none;
  margin: 0 0 0 70px;
}

.comments-form .btn.btn-primary {
  padding: 10px 20px;
}

.comments-form label {
  font-weight: normal;
  color: #959595;
}

/* Sidebar */
.sidebar h3.widget-title {
  font-size: 18px;
  line-height: normal;
  margin-top: 0;
}

.sidebar .widget {
  margin-bottom: 40px;
}

.sidebar-right {
  padding-left: 20px;
}
@media (max-width: 991px) {
  .sidebar-right {
    padding-left: 0;
  }
}

/* Blog search */
.sidebar #search .input-group-btn .btn.btn-primary {
  padding: 11px 12px;
}

/*-- Blog tab widget --*/
.widget-tab .nav-tabs {
  margin: 0;
  text-align: center;
  border-bottom: 1px solid #e7e7e7;
}

.widget-tab .nav-tabs > li:first-child {
  margin-left: 10px;
}

.widget-tab .nav-tabs > li > a {
  background: #f2f2f2;
  color: #323232;
  font-size: 15px;
  font-weight: 700;
  text-transform: capitalize;
  border-radius: 3px 3px 0 0;
  margin-right: -1px;
  border: 1px solid #e7e7e7;
  border-bottom: 0;
  padding: 6px 12px;
}

.widget-tab .nav-tabs > li.active > a {
  background: #fff;
  color: #323232;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 0;
}

.widget-tab .tab-content {
  border-top: 0;
  padding: 10px 0 0;
}

.widget-tab .posts-thumb img {
  display: block;
  width: 85px;
  height: 65px;
  margin-right: 20px;
}

.widget-tab .posts-avator img {
  display: block;
  width: 62px;
  height: 62px;
  margin-right: 20px;
  border-radius: 100%;
}

.widget-tab h4.entry-title {
  font-size: 11px;
  line-height: 15px;
  margin-bottom: 5px;
}

.widget-tab h4.entry-title a {
  color: #323232;
}

.widget-tab .post-meta span {
  padding-right: 5px;
}

.widget-tab ul.posts-list li {
  padding-bottom: 10px;
}

/*-- Blog category widget --*/
.widget-categories ul.category-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget-categories ul.category-list li {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dedede;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.widget-categories ul.category-list li:hover {
  padding-left: 10px;
}

.widget-categories ul.category-list li a {
  color: #323232;
}

.widget-categories ul.category-list li:last-child {
  border-bottom: 0;
}

/*-- Blog tags widget --*/
.widget-tags ul > li {
  float: left;
  margin: 6px;
}

.widget-tags ul > li a {
  background: #f2f2f2;
  color: #6A6A6A;
  display: block;
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 25px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.widget-tags ul > li a:hover {
  color: #007bff;
}

/* Landing page
================================================== */
/* Header */
.landing-header.header-bgnone {
  background: rgba(0, 0, 0, 0.8);
}

.hero-section {
  height: 100%;
  background-image: url(../images/landing/main-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: top center;
  padding-bottom: 0;
  padding-top: 200px;
  overflow: hidden;
}

.hero-section .container {
  height: 100%;
}

.hero-content {
  position: relative;
  display: table;
  height: 100%;
  width: 100%;
}

.hero-text {
  display: table-cell;
  vertical-align: bottom;
  color: #fff;
  padding-right: 50%;
  width: 100%;
  padding-bottom: 10%;
}

.hero-title {
  font-size: 40px;
  line-height: 50px;
  color: #fff;
}

.hero-description {
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 30px;
  color: #fff;
}

.hero-images {
  display: block;
  position: absolute;
  bottom: 80px;
  right: 0;
  width: 50%;
  max-height: 100%;
}

.hero-images img {
  display: block;
  position: relative;
  max-height: 100%;
  width: auto;
}

.hero-buttons a.btn {
  color: #fff;
  padding: 15px 50px;
}

.hero-buttons a.btn.solid {
  margin-left: 10px;
}

.hero-buttons a i {
  margin-left: 5px;
}

/* Facts */
.landing_facts {
  color: #fff;
  padding: 50px 0;
}

.landing_facts .facts i {
  font-size: 36px;
}

.landing_facts .facts-num span {
  font-size: 56px;
}

/* Video and video background */
.video-embed {
  position: relative;
  padding-top: 56.25%;
  /* 16:9 */
  height: 0;
  background-color: #000000;
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.video-embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-background-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

iframe {
  border: 0;
}

@media (min-width: 768px) {
  .video-section {
    padding: 100px 0;
  }
}
@media (min-width: 992px) {
  .video-section {
    padding: 120px 0;
  }
}
/* Screenshot image */
.app-gallery .grid {
  display: block;
  margin: 0 15px 20px;
  padding: 5px;
}

.app-gallery figure.effect-oscar a {
  margin: 125px 5px 0;
}

.app-gallery figure.effect-oscar figcaption::before {
  border: 0;
}

/* Subscribe Form */
.newsletter-form {
  position: relative;
}

.newsletter-form button {
  position: absolute;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 !important;
  padding: 14px 30px 12px !important;
}

/* Clients */
.landing_page_clients {
  padding-top: 30px;
}

/* Elements page
================================================== */
.testimonial.elements .testimonial-text,
.testimonial.elements .testimonial-content h3.name,
.testimonial.elements .testimonial-content h3 span {
  color: #323232;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.testimonial.elements .testimonial-slide.owl-theme .owl-controls .owl-page span {
  border: 1px solid #e7e7e7;
}

/* Style Swithcer
================================================== */
.style-switch-wrapper {
  position: fixed;
  top: 100px;
  right: -250px;
  width: 250px;
  height: auto;
  background-color: rgba(0, 0, 0, 0.75);
  border-bottom-right-radius: 4px;
  padding: 20px 20px;
  z-index: 1000;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  -o-transition: all 0.35s;
  transition: all 0.35s;
}

.style-switch-wrapper h3 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 20px;
}

.style-switch-wrapper.active {
  right: 0;
}

.style-switch-button {
  position: absolute;
  top: 0;
  right: 250px;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.75);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  z-index: 1000;
  cursor: pointer;
}

.style-switch-button i {
  color: #FFF;
  position: relative;
  line-height: 1;
  top: 15px;
  left: 15px;
  font-size: 20px;
}

.style-switch-wrapper button {
  width: 30px;
  height: 30px;
  border-radius: 100% !important;
  margin-bottom: 5px;
  color: #fff;
  display: inline-block;
  padding: 0 !important;
  margin-right: 2px;
  border: 0 !important;
}

a.close-styler {
  color: #fff !important;
  padding: 7px 20px !important;
  margin-right: 6px;
  cursor: pointer;
}
a.close-styler:hover {
  color: #000 !important;
}

.style-switch-wrapper button:active,
.style-switch-wrapper button:focus {
  border: 2px solid #FFF;
}

.style-switch-wrapper #preset1 {
  background-color: #49a2e7;
}

.style-switch-wrapper #preset2 {
  background-color: #81c83c;
}

.style-switch-wrapper #preset3 {
  background-color: #ee3b24;
}

.style-switch-wrapper #preset4 {
  background-color: #ffc400;
}

.style-switch-wrapper #preset5 {
  background-color: #334F96;
}

.style-switch-wrapper #preset6 {
  background-color: #8C42BA;
}

section.hero-slider {
  padding: 0;
}
section.hero-slider .slider-item {
  height: 750px;
  outline: none !important;
}
section.hero-slider .slider-item > div {
  height: 100%;
}
section.hero-slider .slider-item .overlay2 {
  z-index: 1;
}
section.hero-slider .slider-item .overlay2::before {
  z-index: -1 !important;
}
section.hero-slider .slider-item .container {
  padding-top: 20px;
  padding-bottom: 80px;
  transition: 0.3s;
  transition-delay: 0.5s;
  opacity: 0;
  transform: translateX(-50px);
}
section.hero-slider .slick-active .container {
  opacity: 1;
  transform: translateX(0);
}
section.hero-slider h1,
section.hero-slider h2,
section.hero-slider h3,
section.hero-slider h4,
section.hero-slider h5,
section.hero-slider h6,
section.hero-slider p {
  color: #fff;
}
section.hero-slider h2 {
  font-size: 3.2em;
  line-height: 1.3;
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  section.hero-slider h2 {
    font-size: 2.7em;
  }
}
@media (max-width: 575px) {
  section.hero-slider h2 {
    font-size: 30px;
  }
}
section.hero-slider h3 {
  font-size: 24px;
}
@media (max-width: 991px) {
  section.hero-slider h3 {
    font-size: 20px;
  }
}
@media (max-width: 575px) {
  section.hero-slider h3 {
    font-size: 18px;
  }
}
section.hero-slider .h3 {
  font-size: 2.5em !important;
}
@media (max-width: 991px) {
  section.hero-slider .h3 {
    font-size: 1.6em;
  }
}
section.hero-slider p {
  font-size: 1.2em;
}
@media (max-width: 991px) {
  section.hero-slider p {
    font-size: 1em;
  }
}

ul.slick-dots {
  width: 350px;
  position: absolute;
  left: 50%;
  list-style: none;
  padding: 0;
  bottom: 70px;
  transform: translateX(-50%);
}
@media (max-width: 400px) {
  ul.slick-dots {
    width: 320px;
  }
}
ul.slick-dots li i {
  font-size: 32px;
  display: block;
}
ul.slick-dots li {
  color: #b8b8b8;
  outline: none !important;
  transition: 0.3s;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  margin-right: 7px;
  padding: 6px 10px;
  cursor: pointer;
  display: inline-block;
  width: 75px;
}
@media (max-width: 400px) {
  ul.slick-dots li {
    width: 60px;
  }
}
ul.slick-dots li:last-child {
  margin-right: 0;
}
ul.slick-dots .slick-active {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.background-video video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Responsive styles
================================================== */
/* Medium Devices, Desktops */
@media (min-width: 992px) {
  .flexslider .slides img,
.ts-flex-slider .flexSlideshow {
    height: 750px;
  }

  .dropdown i {
    display: none;
  }
}
/* Small Devices, Tablets */
@media (min-width: 768px) and (max-width: 991px) {
  /* Common */
  .parallax {
    padding: 60px 0;
  }

  /* Slideshow */
  #main-slide .slider-content h2 {
    font-size: 36px;
  }

  #main-slide .slider-content h3 {
    font-size: 22px;
    margin: 20px 0;
  }

  .slider.btn {
    padding: 5px 25px;
    margin-top: 5px;
    font-size: 16px;
  }

  #main-slide .carousel-indicators {
    bottom: 20px;
  }

  /* Cd Hero Slider */
  .cd-hero-slider .cd-btn {
    margin-top: 15px !important;
    font-size: 12px !important;
  }

  .cd-slider-nav ul li.video {
    display: none;
  }

  .cd-slider-nav {
    bottom: 40px !important;
  }

  /* Portfolio */
  .isotope-nav {
    margin: 0 auto 40px;
  }

  figure.effect-oscar figcaption {
    padding: 1.5em;
  }

  figure.effect-oscar figcaption::before {
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
  }

  figure.effect-oscar h3 {
    margin-top: 0;
    font-size: 16px;
  }

  /* Feature */
  .feature-content h3,
.feature-center-content h3 {
    font-size: 14px;
    line-height: normal;
  }

  /* Featured tab */
  .featured-tab .tab-pane img {
    width: 45%;
  }

  .featured-tab .tab-pane i.big {
    font-size: 150px;
  }

  .featured-tab .tab-pane,
.landing-tab .tab-pane {
    text-align: left;
  }

  /* Team */
  .team {
    padding-bottom: 50px;
  }

  /* Pricing */
  .plan {
    margin-bottom: 60px;
  }

  /* Testimonial */
  .testimonial-content {
    padding: 0 30px;
  }

  /* Recent post */
  .recent-post {
    padding-bottom: 40px;
  }

  /* Back to top */
  #back-to-top.affix {
    bottom: 35px;
  }

  /* Video block */
  .video-block-head {
    margin-top: 30px;
  }

  /* Call to action */
  .call-to-action .btn.btn-primary {
    font-size: 12px;
  }

  /*-- Landing ---*/
  .hero-text {
    width: 95%;
  }

  .hero-title {
    font-size: 36px;
  }

  .hero-buttons a.btn {
    padding: 10px 20px;
  }

  .app-gallery figure.effect-oscar a {
    margin-top: 105px;
  }

  /* Blog single */
  .blog-post {
    padding-bottom: 40px;
  }
}
/* Small Devices Potrait */
@media (max-width: 767px) {
  /* Common */
  .parallax {
    padding: 60px 0;
  }

  .style-switch-button {
    display: none;
  }

  .heading {
    text-align: center;
  }

  .heading .title-icon {
    float: none !important;
    margin: 0 0 20px;
    display: inline-block;
  }

  h2.title {
    font-size: 28px;
    border-left: 0;
  }

  .title-desc {
    line-height: normal;
    padding: 6px 55px 0;
  }

  .btn.btn-primary {
    padding: 8px 22px;
  }

  h2 {
    font-size: 28px;
  }

  /* Slideshow */
  #main-slide .slider-content h2 {
    font-size: 18px;
    line-height: normal;
  }

  #main-slide .slider-content h3 {
    line-height: normal;
    margin: 0;
  }

  .slider.btn {
    display: none;
  }

  #main-slide .carousel-control {
    display: none;
  }

  #main-slide .carousel-indicators {
    bottom: 0;
  }

  /* About */
  .about {
    padding-top: 40px;
  }

  .about.angle:before {
    position: relative;
  }

  /* Service */
  /* Portfolio */
  .isotope-nav {
    margin: 0 auto 40px;
  }

  .isotope-nav ul li a {
    padding: 5px 10px;
    display: inline-block;
  }

  figure.effect-oscar h3 {
    margin-top: 25%;
  }

  /* Image block */
  .img-block-right {
    padding-right: 50px;
  }

  .img-block-head h2 {
    font-size: 16px;
  }

  .img-block-head h3 {
    font-size: 28px;
  }

  .image-block-content {
    padding: 0;
  }

  /* Featured tab */
  .featured-tab {
    padding: 0 30px;
  }

  .featured-tab .tab-pane i.big {
    font-size: 150px;
  }

  .featured-tab .tab-pane,
.landing-tab .tab-pane {
    text-align: left;
    padding-left: 0;
    padding-top: 30px;
  }

  /* Facts */
  .facts {
    padding-bottom: 20px;
  }

  .facts-num span {
    font-size: 48px;
    margin-top: 20px;
  }

  .facts i {
    font-size: 32px;
  }

  /* Team */
  .team {
    padding-bottom: 50px;
  }

  /* Pricing */
  .plan {
    margin-bottom: 60px;
  }

  /* Testimonial */
  .testimonial-content {
    padding: 0 30px;
  }

  /* Recent post */
  .recent-post {
    padding-bottom: 40px;
  }

  /* Footer */
  .footer-widget {
    display: inline-block;
  }

  /* Copyright */
  .copyright-info {
    font-size: 13px;
  }

  #back-to-top {
    right: 20px;
  }

  #back-to-top.affix {
    bottom: 20px;
  }

  /* Call action */
  .call-to-action .btn.btn-primary {
    float: none !important;
    margin-top: 20px;
  }

  /*-- Landing ---*/
  .hero-section {
    padding-top: 90px;
  }

  .hero-text {
    width: 100%;
    padding-right: 0;
  }

  .hero-title {
    font-size: 36px;
  }

  .hero-buttons a.btn {
    padding: 10px 20px;
  }

  .app-gallery figure.effect-oscar a {
    margin-top: 105px;
  }

  .hero-images img {
    display: none;
  }

  .landing_facts .facts-num span {
    font-size: 48px;
    margin-top: 20px;
  }

  .landing_facts .facts i {
    font-size: 30px;
  }

  /* Blog single */
  .blog-post {
    padding-bottom: 40px;
  }
}
/* Extra Small Devices, Phones */
@media (max-width: 479px) {
  /* Slideshow */
  #main-slide .slides img {
    height: 230px;
  }

  #main-slide .item img {
    height: 250px;
  }
}