
// 

.btn{
	color: #323232;
	border-radius: 50px;
	padding: 10px 30px;
	background: none;
	font-size: 14px;
	text-transform: uppercase;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	-ms-transition: all 0.3s ease 0s;
	-o-transition: all 0.3s ease 0s;
	transition: all 0.3s ease 0s;
	border-width: 2px;

	&:focus{
		box-shadow: none;
	}
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
	box-shadow: none;
}
.btn-outline-white{
	border: 2px solid $white;
	color: $white;

	&:hover {
		color: $black;
		background-color: $white;
	}
}
.btn-outline-primary{
	border: 2px solid $primary-color;
	// color: $white;

	&:hover {
		color: $white;
		background-color: $primary-color;
	}
}